import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'
import Category from './item'

import StoreActions from '../../Redux/Store/actions'

import { useDispatch, useSelector } from 'react-redux'

const CategoriesScreen = () => {

  const dispatch = useDispatch()

  const categories = useSelector( state => state.store.categories )

  useEffect( () => {
    dispatch( StoreActions.getCategories() )
  }, [dispatch])

  return (
    <div>
      <NavigationTopBar />
      <div className="container pt-5">
        <h1 className="title is-6">Categorias</h1>
        <div className="columns is-multiline pt-4">
          { categories && categories.map( category => (
            <div className="column">
              <Category value={category} />
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}

export default CategoriesScreen
