import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import StoreActions from '../../Redux/Store/actions'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'
import styles from './store-styles.module.sass'

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Campo requerido *'),
  summary: Yup.string()
    .required('Campo requerido *'),
  nit: Yup.string()
    .required('Campo requerido *'),
  logo: Yup.string()
    .required('Campo requerido *'),
  commercial_registration: Yup.string()
    .required('Campo requerido *'),
})

const StoreScreen = ({ history }) => {

  const dispatch = useDispatch()

  const store = useSelector( state => state.store.data )

  const {
    createStoreLoading,
    // updatingError, // TODO: SHOW ERROR
    createdStore
  } = useSelector( state => state.store )

  const createOrUpdateStore = values => {
    if (store) {
      dispatch( StoreActions.updateStore(store._id, values) )
    }else {
      dispatch( StoreActions.createStore(values) )
    }
  }

  useEffect( () => {
    if (createdStore) {
      history.push('/user/legal-representative')
    }
  }, [ createdStore, history ])

  return (
    <div>
      <NavigationTopBar />
      <section className="section">
        <div className="container">
          <h1 className="title">Datos de la tienda</h1>
          <h2 className="subtitle">
            A simple container to divide your page into <strong>sections</strong>, like the one you're currently reading
          </h2>
          <div>
            <Formik
              initialValues={ store || { name: "", summary: "", nit: "" } }
              validationSchema={validationSchema}
              onSubmit={ values => createOrUpdateStore(values) }>

              { ({ handleSubmit, setFieldValue, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Nombre</label>
                    <div className="control">
                      <Field className="input" type="text" name="name" />
                      <ErrorMessage name="name" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Descripción</label>
                    <div className="control">
                      <Field className="input" type="text" name="summary" />
                      <ErrorMessage name="summary" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Nit</label>
                    <div className="control">
                    <Field className="input" type="text" name="nit" />
                    <ErrorMessage name="nit" component="span" className="has-text-danger" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Logo</label>
                    { values.logo instanceof File ? (
                      <div>
                        <img
                          className={styles.logo}
                          src={ URL.createObjectURL(values.logo)  }
                          alt="" />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={styles.logo}
                          src={ values.logo }
                          alt="" />
                      </div>
                    )}
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          onChange={ event => setFieldValue("logo", event.currentTarget.files[0])} />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload"></i>
                          </span>
                          <span className="file-label">
                            Buscar archivo
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Matricula mercantil</label>
                    { values.commercial_registration instanceof File ? (
                      <div>
                        <img
                          className={styles.commercial_registration}
                          src={ URL.createObjectURL(values.commercial_registration)  }
                          alt="" />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={styles.commercial_registration}
                          src={ values.commercial_registration }
                          alt="" />
                      </div>
                    )}
                    <div className="file">
                      <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        onChange={ event => setFieldValue("commercial_registration", event.currentTarget.files[0])} />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload"></i>
                          </span>
                          <span className="file-label">
                            Buscar archivo
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        type="submit"
                        className={clsx("button","is-link", createStoreLoading ? "is-loading" : null) }>
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              ) }

            </Formik>
          </div>
        </div>
      </section>

    </div>
  )
}

export default StoreScreen
