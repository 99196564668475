import { createActions } from 'redux-actions'

import { login as loginService,
          me as meService,
          signup as signupService,
          updateProfile as updateProfileService,
          changePassword as changePasswordService,
          forgotPassword as forgotPasswordService,
        } from '../../Services/Account'

import { uploadFile } from '../../Services/Files'

import AuthActions from '../Auth/actions'
import StoreActions from '../Store/actions'


const AccountActions = createActions({
  LOGIN_REQUEST: () => {},
  LOGIN_RESPONSE: response => ({ response }),

  SIGN_UP_REQUEST: () => {},
  SIGN_UP_RESPONSE: response => ({ response }),

  UPDATE_PROFILE_REQUEST: () => {},
  UPDATE_PROFILE_RESPONSE: response => ({ response }),
  RESET_UPDATE_PROFILE: () => {},

  CHANGE_PASSWORD_REQUEST: () => {},
  CHANGE_PASSWORD_RESPONSE: response => ({ response }),

  FORGOT_PASSWORD_REQUEST: () => {},
  FORGOT_PASSWORD_RESPONSE: response => ({ response }),
})

AccountActions.login = (email, password) => {
  return async dispatch => {

    try {

      dispatch( AccountActions.loginRequest() )

      const loginResponse = await loginService(email, password)

      console.log('loginResponse: ', loginResponse)

      if (!loginResponse.ok)
        return dispatch( AccountActions.loginResponse( { error: loginResponse.data.error } ) )


      dispatch( AuthActions.setAccessToken(loginResponse.data.access_token) )

      const me = await meService()

      await dispatch(StoreActions.findMyStore())
      dispatch( AuthActions.setUser(me) )

      dispatch(
        AccountActions.loginResponse(me)
      )


    } catch (err) {
      dispatch( AccountActions.loginResponse({ error: err }) )
    }

  }
}


AccountActions.signUp = (email, password) => {
  return async dispatch => {

    try {

      dispatch( AccountActions.signUpRequest() )

      const newUserAttributes = {
        email,
        password,
      }

      const signUpResponse = await signupService(newUserAttributes)

      console.log('signupResponse: ', signUpResponse)

      if (!signUpResponse.ok)
        return dispatch( AccountActions.signUpResponse( { error: signUpResponse.data.error } ) )

      dispatch( AuthActions.setAccessToken(signUpResponse.data.access_token) )

      // save token
      const me = await meService()

      console.log('me: ', me)
      dispatch( AccountActions.signUpResponse({ error: null }) )

      dispatch( AuthActions.setUser(me) )

    } catch (err) {
      dispatch( AccountActions.signUpResponse( { error: err } ) )
    }
  }
}

AccountActions.updateProfileBasics = basics => {
  return async dispatch => {

    dispatch( AccountActions.updateProfileRequest() )

    const dniFile = basics.dni_file
    if( dniFile && (dniFile instanceof File ) ) {
      const dniFileURL = await uploadFile(dniFile)
      basics.dni_file = dniFileURL
    }

    const updateProfileResponse = await updateProfileService(basics)

    console.log('updateProfileResponse: ', updateProfileResponse)
    if (!updateProfileResponse.ok)
      return dispatch( AccountActions.updateProfileResponse( { error: updateProfileResponse.data.error } ) )

    dispatch(
      AccountActions.updateProfileResponse( {
        data: updateProfileResponse.data.data,
      }) )

    dispatch( AuthActions.setUser(updateProfileResponse.data.data) )
  }
}

AccountActions.updateProfile = userParams => {
  return async dispatch => {

    dispatch( AccountActions.updateProfileRequest() )

    const updateProfileResponse = await updateProfileService(userParams)

    console.log('updateProfileResponse: ', updateProfileResponse)
    if (!updateProfileResponse.ok)
      return dispatch( AccountActions.updateProfileResponse( { error: updateProfileResponse.data.error } ) )

    dispatch(
      AccountActions.updateProfileResponse( {
        data: updateProfileResponse.data.data,
      }) )

    dispatch( AuthActions.setUser(updateProfileResponse.data.data) )
  }
}

AccountActions.changePassword = (oldPassword, newPassword) => {
  return async dispatch => {

    dispatch( AccountActions.changePasswordRequest() )

    const changePasswordResponse = await changePasswordService(oldPassword, newPassword)

    console.log('changePasswordResponse: ', changePasswordResponse)
    if (!changePasswordResponse.ok)
      return dispatch( AccountActions.changePasswordResponse( { error: changePasswordResponse.data.error } ) )

    dispatch(
      AccountActions.changePasswordResponse( {
        data: changePasswordResponse.data.data,
      }) )
  }
}

AccountActions.forgotPassword = email => {
  return async dispatch => {

    dispatch( AccountActions.forgotPasswordRequest() )

    const forgotPasswordResponse = await forgotPasswordService(email)

    console.log('forgotPasswordResponse: ', forgotPasswordResponse)
    if (!forgotPasswordResponse.ok)
      return dispatch( AccountActions.forgotPasswordResponse( { error: forgotPasswordResponse.data.error } ) )

    dispatch(
      AccountActions.forgotPasswordResponse( {
        data: forgotPasswordResponse.data.data,
      }) )
  }
}

AccountActions.logOut = () => {
  return dispatch => {
    dispatch( AuthActions.removeAuth() )
  }
}

export default AccountActions
