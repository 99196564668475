import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import StoreActions from '../../Redux/Store/actions'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'
import styles from './legal-styles.module.sass'

const validationSchema = Yup.object({
  fullname_representative: Yup.string()
    .required('Campo requerido *'),
  dni_representative: Yup.string()
    .required('Campo requerido *'),
  // dni_file_representative: Yup.string()
  //   .required('Campo requerido *'),
  // dniType: Yup.string()
  //   .required('Campo requerido *'),
})

const LegalRepresentativeScreen = ({ history }) => {

  const dispatch = useDispatch()

  const store = useSelector( state => state.store.data )

  const {
    updateStoreLoading,
    // updatingError, // TODO: SHOW ERROR
    updatedStore
  } = useSelector( state => state.store )

  useEffect( () => {
    if (updatedStore) {
      history.push('/user/confirmation')
    }
  }, [ updatedStore, history ] )

  return (
    <div>
      <NavigationTopBar />
      <section className="section">
        <div className="container">
          <h1 className="title">Datos del representante legal</h1>
          <h2 className="subtitle">
            A simple container to divide your page into <strong>sections</strong>, like the one you're currently reading
          </h2>
          <div>
            <Formik
              initialValues={ store?.legal ? store.legal : { fullname_representative: "", dni_representative: "" } }
              validationSchema={validationSchema}
              onSubmit={ values => dispatch( StoreActions.updateStoreLegal(values) ) } >

              { ({ handleSubmit, setFieldValue, values }) => (

                <form noValidate onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Nombres completos</label>
                    <div className="control">
                      <Field className="input" type="text" name="fullname_representative" />
                      <ErrorMessage name="fullname_representative" component="span" className="has-text-danger" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Tipo de identificación</label>
                    <div className="select">
                      <select>
                        <option> Cedula de ciudadania </option>
                        <option> Cedula de extranjería </option>
                      </select>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Numero de identificación</label>
                    <div className="control">
                      <Field className="input" type="text" name="dni_representative" />
                      <ErrorMessage name="dni_representative" component="span" className="has-text-danger" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Documento de identificación al 150%</label>
                    { values.dni_file_representative instanceof File ? (
                      <div>
                        <img
                          className={styles.dni_file_representative}
                          src={ URL.createObjectURL(values.dni_file_representative)  }
                          alt="" />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={styles.dni_file_representative}
                          src={ values.dni_file_representative }
                          alt="" />
                      </div>
                    )}
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          name="dni_file_representative"
                          onChange={ event => setFieldValue('dni_file_representative', event.currentTarget.files[0])} />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload"></i>
                          </span>
                          <span className="file-label">
                            Buscar archivo
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="field is-grouped">
                    <div className="control">
                      <button type="submit" className={clsx("button","is-link", updateStoreLoading ? "is-loading" : null) }>Continuar</button>
                    </div>
                  </div>
                </form>

              ) }

            </Formik>

          </div>
        </div>
      </section>

    </div>
  )
}

export default LegalRepresentativeScreen
