import Immutable from 'seamless-immutable'

const INITIAL_STATE = Immutable({
  loginLoading: false,
  loginError: null,
  loggedIn: false,

  signUpLoading: false,
  signUpError: null,
  signedUp: false,

  updatingProfile: false,
  updatedProfile: false,
  updatingError: null,

  changePasswordLoading: false,
  changePasswordError: null,
  changedPassword: false,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSent: false,

})

export default INITIAL_STATE
