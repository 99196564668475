import React from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

const UserConfirmationScreen = ({ history }) => {
  return (
    <div>
      <NavigationTopBar />
      <section className="section">
        <div className="container">
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <h1 className="title has-text-centered">
                <img src={require("../../Assets/img/carrito-compras-logo.png")} alt="" />
              </h1>
              <h2 className="subtitle has-text-centered">
                Sus datos se han enviado con satisfacción, espere confirmación de su solicitud para crear tienda en <b>itto</b> por medio del correo electrónico.
              </h2>
              <div className="has-text-centered">
                <button onClick={ () => history.replace('/') } className="button is-success">Aceptar</button>
              </div>
            </div>
          </div>
        </section>
        </div>
      </section>

    </div>
  )
}

export default UserConfirmationScreen
