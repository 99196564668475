import React, { useState, useEffect, useRef } from 'react'

import NavigationTopBar from '../../../Components/NavigationTopBar'

import { useDispatch, useSelector } from 'react-redux'
import StoreActions from '../../../Redux/Store/actions'
import StoreInitialState from '../../../Redux/Store'

import { Formik } from 'formik'

import NameStep from './Steps/name'
import DescriptionStep from './Steps/description'
import CategoryStep from './Steps/category'
import BrandStep from './Steps/brand'
import PriceStep from './Steps/price'
import SpecificationsStep from './Steps/specifications'
import PhotosStep from './Steps/photos'
import ColorsStep from './Steps/colors'
import PreviewStep from './Steps/preview'

import clsx from 'clsx'

const steps = [
  'Nombre',
  'Descripción',
  'Categoria',
  'Marca',
  'Precio',
  'Especificaciones',
  'Fotos',
  'Color',
  'Preview'
]

const NewArticle = ({ history }) => {

  const dispatch = useDispatch()

  const brands = useSelector( state => state.store.brands )
  const categories = useSelector( state => state.store.categories )

  const createBrandLoading = useSelector( state => state.store.createBrandLoading )
  const createProductLoading = useSelector( state => state.store.createProductLoading )
  const createdProduct = useSelector( state => state.store.createdProduct )

  const uploadingProductPhoto = useSelector( state => state.store.uploadingProductPhoto )
  const uploadedProductPhoto = useSelector( state => state.store.uploadedProductPhoto )

  const [currentStep, setCurrentStep] = useState(0)

  const formRef = useRef()

  useEffect( () => {
    dispatch( StoreActions.getCategories() )
    dispatch( StoreActions.getBrands() )
  }, [dispatch])

  useEffect( () => {
    if (uploadedProductPhoto && formRef.current) {

      const currentPhotoUploaded = {
        source: uploadedProductPhoto,
        isMain: false
      }

      formRef.current.setFieldValue(
        'photos',
        [...formRef.current.values.photos, currentPhotoUploaded]
      );
    }
  }, [formRef, uploadedProductPhoto])

  const CurrentStep = (step, values, handleChange, setFieldValue) => {

    console.log(values)


    switch (step) {
      case 0:
        return <NameStep
                onChange={handleChange}
                value={ values.name } />
      case 1:
        return <DescriptionStep
                onChange={handleChange}
                value={ values.description } />
      case 2:
        return <CategoryStep
                onChange={ category => {
                  setFieldValue('category', category)
                }}
                values={categories}
                value={ values.category } />
      case 3:
        return <BrandStep
                onChange={handleChange}
                onNew={ name => {
                  dispatch( StoreActions.createBrand(name) )
                }}
                onSelect={ brand => {
                  setFieldValue('brand', brand)
                } }
                loading={ createBrandLoading }
                values={brands}
                value={ values.brand } />
      case 4:
        return <PriceStep
                  onChange={handleChange}
                  value={ values.price } />
      case 5:
        return <SpecificationsStep
                onChange={handleChange}
                value={ values.full_description } />
      case 6:
        return <PhotosStep
                isLoading={uploadingProductPhoto}
                onSelectFiles={ files => {
                  dispatch( StoreActions.uploadProductPhoto(files[0]) )
                }}
                onMarkAsMain={ index => {
                  const newPhotos = values.photos.map( (photo, idx) => ({ ...photo, isMain: index === idx }) )
                  setFieldValue('photos', newPhotos)
                }}
                onDelete={ index => {
                  const newPhotos = values.photos.filter( (photo, idx) => index !== idx )
                  setFieldValue('photos', newPhotos)
                }}
                photos={values.photos} />
      case 7:
        return <ColorsStep
                onChange={handleChange}
                colors={values.colors} />
      case 8:
        return <PreviewStep
                values={values}
                photos={[]} />
      default: return
    }
  }


  return (
    <div>
      <NavigationTopBar />
      { createdProduct && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-body">
              <p>
                Product nuevo agregado a la tienda!
              </p>
              <button
                className="button is-success"
                onClick={ () => {
                  dispatch( StoreActions.resetCreateProduct() )
                  history.goBack()
                } }>
                Continuar
              </button>
            </section>
          </div>
        </div>
      ) }

      <div className="container">
        <Formik
          innerRef={formRef}
          initialValues={StoreInitialState.newProduct}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(StoreActions.createProduct(values))
          }}>
          { ({ values, handleChange, handleSubmit, setFieldValue}) => (
            <form onSubmit={handleSubmit}>

              <section className="hero is-fullheight-with-navbar">
                <section className="hero-head pt-6">
                  <ul className="steps has-content-centered">
                    { steps.map( (step, index) => (
                      <li
                        key={`article-step-${index}`}
                        className={clsx("steps-segment", currentStep === index ? "is-active" : null)}
                        onClick={ () => setCurrentStep(index)}>
                        <span className="steps-marker"></span>
                        { step !== "" && (
                          <div className="steps-content">
                            <p className="is-size-4">{step}</p>
                          </div>
                        )}
                      </li>
                    )) }
                  </ul>
                </section>
                <section className="hero-body" style={{ alignItems: 'flex-start' }}>
                  <div  className="container">
                    <div className="columns is-vcentered">
                      <div className="column is-full has-text-centered">
                        { CurrentStep(currentStep, values, handleChange, setFieldValue) }
                      </div>
                    </div>
                  </div>
                </section>

                <section className="hero-foot pb-6">
                  <div className="level">
                    <div className="level-left" />
                    <div className="level-right">
                      <div className="buttons are-medium">
                        { currentStep !== 0 && currentStep < steps.length - 1 && (
                          <button
                            type="button"
                            onClick={ () => setCurrentStep(currentStep - 1)}
                            className="button">
                            Anterior
                          </button>
                        )}
                        { currentStep < steps.length - 1  && (
                          <button
                            type="button"
                            onClick={ () => setCurrentStep(currentStep + 1)}
                            className="button">
                            Siguiente
                          </button>
                        )}

                        { currentStep === steps.length - 1  && (
                          <button
                            type="submit"
                            disabled={createProductLoading}
                            className={clsx("button", "is-success", createProductLoading ? "is-loading" : null )}>
                            Guardar
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </form>
          )}
        </Formik>
      </div>

    </div>
  )
}

export default NewArticle
