import React, { useState, useEffect } from 'react'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import styles from './styles.module.sass'

const SpecificationsStep = ({ value, onChange }) => {

  const [contentState, setContentState] = useState(null)

  useEffect( () => {
    setContentState({})
  }, [])

  return (
    <div className={styles.container_editor_specifications}>
      <Editor
        initialContentState={contentState}
        onContentStateChange={ state => setContentState(state)} />
    </div>
  )
}

export default SpecificationsStep
