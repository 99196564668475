import React from 'react'

const PriceStep = ({ value, onChange }) => {
  return (
    <div>
      <div className="field">
        <p className="control has-icons-left">
          <input
            className="input"
            type="text"
            name="price"
            placeholder="Precio"
            value={value}
            onChange={onChange} />
          <span className="icon is-small is-left">
            <i className="fa fa-usd" aria-hidden="true"></i>
          </span>
        </p>
      </div>
    </div>
  )
}

export default PriceStep
