import React from 'react'

import PhotoManager from '../../../../Components/PhotoManager'

const PhotosStep = ({ photos, onSelectFiles, onDelete, onMarkAsMain, isLoading }) => {
  return (
    <PhotoManager
      isLoading={isLoading}
      onSelectFiles={ onSelectFiles }
      onDelete={onDelete}
      onMarkAsMain={onMarkAsMain}
      photos={photos} />
  )
}

export default PhotosStep
