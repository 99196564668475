import React from 'react'

const Category = ({ value }) => {
  return (
    <div className="card">
      {/*<div className="card-image">
        <figure className="image is-4by3">
          <img src={value?.photos?.[0]?.source} alt="" />
        </figure>
      </div>*/}
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{ value?.name }</p>
          </div>
        </div>

        <div className="content">
          { value?.description }
        </div>
      </div>
    </div>
  )
}

export default Category
