import InitialState from './'
import StoreActions from './actions'

export function reducer(state = InitialState, action) {
  switch (action.type) {
    case StoreActions.createStoreRequest().type:
      return state.merge({
        createStoreLoading: true,
        createStoreError: null,
        createdStore: false
      })
    case StoreActions.createStoreResponse().type:
      return state.merge({
        createStoreLoading: false,
        createStoreError: action.payload.response.error,
        createdStore: action.payload.response.store !== undefined,
        data: action.payload.response.store
      })

    case StoreActions.findMyStoreRequest().type:
      return state.merge({
        findStoreLoading: true,
        findStoreError: null,
      })
    case StoreActions.findMyStoreResponse().type:
      return state.merge({
        findStoreLoading: false,
        findStoreError: action.payload.response.error,
        data: action.payload.response.store,
      })

    case StoreActions.updateStoreRequest().type:
      return state.merge({
        updateStoreLoading: true,
        updateStoreError: null,
        updatedStore: false
      })
    case StoreActions.updateStoreResponse().type:
      return state.merge({
        updateStoreLoading: false,
        updateStoreError: action.payload.response.error,
        updatedStore: action.payload.response.store !== undefined,
        data: action.payload.response.store
      })

    case StoreActions.getBrandsRequest().type:
      return state.merge({
        getBrandsLoading: true,
        getBrandsError: null,
      })
    case StoreActions.getBrandsResponse().type:
      return state.merge({
        getBrandsLoading: false,
        getBrandsError: action.payload.response.error,
        brands: action.payload.response.brands,
      })

    case StoreActions.getCategoriesRequest().type:
      return state.merge({
        getCategoriesLoading: true,
        getCategoriesError: null,
      })
    case StoreActions.getCategoriesResponse().type:
      return state.merge({
        getCategoriesLoading: false,
        getCategoriesError: action.payload.response.error,
        categories: action.payload.response.categories,
      })

    case StoreActions.createBrandRequest().type:
      return state.merge({
        createBrandLoading: true,
        createBrandError: null,
      })
    case StoreActions.createBrandResponse().type:
      return state.merge({
        createBrandLoading: false,
        createBrandError: action.payload.response.error,
        brands: [...state.brands, action.payload.response.brand],
      })

    case StoreActions.createProductRequest().type:
      return state.merge({
        createProductLoading: true,
        createProductError: null,
      })
    case StoreActions.createProductResponse().type:
      return state.merge({
        createProductLoading: false,
        createProductError: action.payload.response.error || null,
        createdProduct: action.payload.response.error === undefined,
        products: [...state.products, action.payload.response.product],
      })
    case StoreActions.resetCreateProduct().type:
      return state.merge({
        createProductLoading: false,
        createProductError: null,
        createdProduct: false,
      })

    case StoreActions.getOurProductsRequest().type:
      return state.merge({
        getOurProductsLoading: true,
        getOurProductsError: null,
      })
    case StoreActions.getOurProductsResponse().type:
      return state.merge({
        getOurProductsLoading: false,
        getOurProductsError: action.payload.response.error || null,
        products: action.payload.response.products,
      })

    case StoreActions.uploadProductPhotoRequest().type:
      return state.merge({
        uploadingProductPhoto: true,
        uploadProductPhotoError: null,
      })
    case StoreActions.uploadProductPhotoResponse().type:
      return state.merge({
        uploadingProductPhoto: false,
        uploadProductPhotoError: action.payload.response.error || null,
        uploadedProductPhoto: action.payload.response.photo
      })

    default: return state
  }
}
