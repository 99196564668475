import React from 'react'

import styles from './index.module.sass'

import clsx from 'clsx'

const Product = ({ value }) => {
  return (
    <div className={clsx('card', styles.card)}>
      <div className="card-image">
        <figure className="image is-4by3">
          <img className={styles.image} src={value?.photos?.[0]?.source} alt="" />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{ value?.name }</p>
            <p className="subtitle is-6">{ value?.brand?.name }</p>
          </div>
        </div>

        <div className="content">
          <div>
            { value?.description }
          </div>
          <div className="has-text-info is-size-3 has-text-weight-bold">
            ${ value?.price }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
