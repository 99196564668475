import ACCOUNT_STATE from './'
import AccountActions from './actions'

export function reducer(state = ACCOUNT_STATE, action) {

  switch (action.type) {

    case AccountActions.loginRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loggedIn: false })
    case AccountActions.loginResponse().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.response.error,
        loggedIn: action.payload.response.error === null,
      })



    case AccountActions.signUpRequest().type:
      return state.merge({
        signUpLoading: true,
        signUpResponse: null,
        signedUp: false })
    case AccountActions.signUpResponse().type:
      return state.merge({
        signUpLoading: false,
        signUpError: action.payload.response.error,
        signedUp: action.payload.response.error === null,
      })



    case AccountActions.updateProfileRequest().type:
      return state.merge({
        updatingProfile: true,
        updateResponse: null,
        updatedProfile: false })
    case AccountActions.updateProfileResponse().type:
      return state.merge({
        updatingProfile: false,
        updatingError: action.payload.response.error,
        updatedProfile: action.payload.response.error !== null,
      })

    case AccountActions.resetUpdateProfile().type:
      return state.merge({
        updatingProfile: false,
        updatingError: null,
        updatedProfile: false,
      })

    case AccountActions.changePasswordRequest().type:
      return state.merge({
        changePasswordLoading: true,
        changePasswordError: null,
        changedPassword: false })
    case AccountActions.changePasswordResponse().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: action.payload.response.error,
        changedPassword: action.payload.response.error === null,
      })



    case AccountActions.forgotPasswordRequest().type:
      return state.merge({
        forgotPasswordLoading: true,
        forgotPasswordError: null,
        forgotPasswordSent: false })
    case AccountActions.forgotPasswordResponse().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload.response.error,
        forgotPasswordSent: action.payload.response.error === null,
      })
    default:
      return state
  }
}
