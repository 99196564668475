import { create } from 'apisauce'
import api from './'

export const uploadFile = async file => {
  try {

    const signedURLResponse = await api.post('/files', { fileName: file.name })

    console.log('signedURLResponse:', signedURLResponse)

    if (!signedURLResponse.ok) {
      throw Error('unable to upload file')
    }

    const uploadFileRequest = create({
      baseURL: signedURLResponse.data.signedURL,
      headers: {
        'Content-Type': file.type
      }
    })

    const uploadFileResponse = await uploadFileRequest.put('/', file)

    console.log('uploadFileResponse:', uploadFileResponse)

    if (!uploadFileResponse.ok) {
      throw Error('unable to upload file')
    }

    return signedURLResponse.data.fileURL

  } catch (err) {
    throw err
  }
}
