import { createActions } from 'redux-actions'

import {
  createStore,
  findMyStore,
  updateStore,
  getCategories,
  getBrands,
  createBrand,
  createProduct,
  getOurProducts,
} from '../../Services/Store'

import {
  uploadFile
} from '../../Services/Files'

const StoreActions = createActions({
  SET_NEW_PRODUCT_NAME: name => ({ name }),
  SET_NEW_PRODUCT_DESCRIPTION: description => ({ description }),
  SET_NEW_PRODUCT_CATEGORY: category => ({ category }),
  SET_NEW_PRODUCT_BRAND: brand => ({ brand }),
  ADD_NEW_PRODUCT_PHOTO: photo => ({ photo }),
  SET_NEW_PRODUCT_FULL_DESCRIPTION: fullDescription => ({ fullDescription }),
  SET_NEW_PRODUCT_PRICE: price => ({ price }),
  ADD_NEW_PRODUCT_AVAILABLE_COLOR: availableColor => ({ availableColor }),

  CREATE_STORE_REQUEST: () => {},
  CREATE_STORE_RESPONSE: response => ({ response }),

  FIND_MY_STORE_REQUEST: () => {},
  FIND_MY_STORE_RESPONSE: response => ({ response }),

  UPDATE_STORE_REQUEST: () => {},
  UPDATE_STORE_RESPONSE: response => ({ response }),

  GET_BRANDS_REQUEST: () => {},
  GET_BRANDS_RESPONSE: response => ({ response }),

  CREATE_BRAND_REQUEST: () => {},
  CREATE_BRAND_RESPONSE: response => ({ response }),

  GET_CATEGORIES_REQUEST: () => {},
  GET_CATEGORIES_RESPONSE: response => ({ response }),

  CREATE_PRODUCT_REQUEST: () => {},
  CREATE_PRODUCT_RESPONSE: response => ({ response }),
  RESET_CREATE_PRODUCT: () => {},

  GET_OUR_PRODUCTS_REQUEST: () => {},
  GET_OUR_PRODUCTS_RESPONSE: response => ({ response }),

  UPLOAD_PRODUCT_PHOTO_REQUEST: () => {},
  UPLOAD_PRODUCT_PHOTO_RESPONSE: response => ({ response }),

})


StoreActions.createStore = store => {
  return async dispatch => {

    try {

      dispatch( StoreActions.createStoreRequest() )

      const logoFile = store.logo
      const logoFileURL = await uploadFile(logoFile)
      store.logo = logoFileURL

      const commercialRegistrationFile = store.commercial_registration
      const commercialRegistrationFileURL = await uploadFile(commercialRegistrationFile)
      store.commercial_registration = commercialRegistrationFileURL

      const storeCreationResponse = await createStore({ ...store })

      if (!storeCreationResponse.ok) {
        const error = { userMessage: "", debugMessage: storeCreationResponse.problem }
        return dispatch( StoreActions.createStoreResponse({ error }) )
      }

      dispatch( StoreActions.createStoreResponse({ store: storeCreationResponse.data }) )

    } catch (err) {
      const error = { userMessage: "", debugMessage: err }
      dispatch( StoreActions.createStoreResponse({ error }) )
    }

  }
}

StoreActions.findMyStore = () => {
  return async dispatch => {
    try {

      dispatch( StoreActions.findMyStoreRequest() )

      const storeResponse = await findMyStore()

      if (!storeResponse.ok) {
        const error = { userMessage: '', debugMessage: storeResponse.problem }
        return dispatch( StoreActions.findMyStoreResponse({ error }) )
      }

      dispatch( StoreActions.findMyStoreResponse({ store: storeResponse.data }) )

    } catch (err) {
      return dispatch( StoreActions.findMyStoreResponse({ error: err }) )
    }

  }
}

StoreActions.updateStore = (storeId, params) => {
  return async dispatch => {
    try {

      dispatch( StoreActions.updateStoreRequest() )

      const logoFile = params.logo

      if (logoFile && (logoFile instanceof File)) {
        const logoFileURL = await uploadFile(logoFile)
        params.logo = logoFileURL
      }

      const commercialRegistrationFile = params.commercial_registration

      if (commercialRegistrationFile && (commercialRegistrationFile instanceof File)) {
        const commercialRegistrationFileURL = await uploadFile(commercialRegistrationFile)
        params.commercial_registration = commercialRegistrationFileURL
      }

      const storeResponse = await updateStore(storeId, params)

      if (!storeResponse.ok) {
        const error = { userMessage: '', debugMessage: storeResponse.problem }
        return dispatch( StoreActions.updateStoreResponse({ error }) )
      }

      dispatch( StoreActions.updateStoreResponse({ store: storeResponse.data }) )

    } catch (err) {
      console.log(err)
      return dispatch( StoreActions.updateStoreResponse({ error: err }) )
    }

  }
}

StoreActions.updateStoreLegal = (params) => {
  return async (dispatch, getState) => {
    try {

      dispatch( StoreActions.updateStoreRequest() )

      const store = getState().store.data

      console.log('store: ', store)

      const dniFileRepresentative = params.dni_file_representative

      if (dniFileRepresentative && (dniFileRepresentative instanceof File)) {
        const dniFileRepresentativeURL = await uploadFile(dniFileRepresentative)
        params.dni_file_representative = dniFileRepresentativeURL
      }

      const storeResponse = await updateStore(store._id, { legal: params } )

      console.log('Update store legal response: ', storeResponse)

      if (!storeResponse.ok) {
        const error = { userMessage: '', debugMessage: storeResponse.problem }
        return dispatch( StoreActions.updateStoreResponse({ error }) )
      }

      dispatch( StoreActions.updateStoreResponse({ store: storeResponse.data }) )

    } catch (err) {
      return dispatch( StoreActions.updateStoreResponse({ error: err }) )
    }

  }
}

StoreActions.getCategories = () => {
  return async dispatch => {
    dispatch( StoreActions.getCategoriesRequest() )

    const response = await getCategories()

    if (!response.ok) {
      const error = { userMessage: '', debugMessage: response.problem }
      return dispatch( StoreActions.getCategoriesResponse({ error }) )
    }

    dispatch( StoreActions.getCategoriesResponse({ categories: response.data }) )
  }
}

StoreActions.getBrands = () => {
  return async dispatch => {
    dispatch( StoreActions.getBrandsRequest() )

    const response = await getBrands()

    if (!response.ok) {
      const error = { userMessage: '', debugMessage: response.problem }
      return dispatch( StoreActions.getBrandsResponse({ error }) )
    }

    dispatch( StoreActions.getBrandsResponse({ brands: response.data }) )
  }
}

StoreActions.createBrand = name => {
  return async dispatch => {
    dispatch( StoreActions.createBrandRequest() )

    const response = await createBrand(name)

    if (!response.ok) {
      const error = { userMessage: '', debugMessage: response.problem }
      return dispatch( StoreActions.createBrandResponse({ error }) )
    }

    dispatch( StoreActions.createBrandResponse({ brand: response.data }) )
  }
}

StoreActions.createProduct = params => {
  return async dispatch => {
    dispatch( StoreActions.createProductRequest() )

    const response = await createProduct(params)

    if (!response.ok) {
      const error = { userMessage: '', debugMessage: response.problem }
      return dispatch( StoreActions.createProductResponse({ error }) )
    }

    dispatch( StoreActions.createProductResponse({ product: response.data }) )
  }
}

StoreActions.getOurProducts = () => {
  return async dispatch => {

    dispatch( StoreActions.getOurProductsRequest() )

    const response = await getOurProducts()

    if (!response.ok) {
      const error = { userMessage: '', debugMessage: response.problem }
      return dispatch( StoreActions.getOurProductsResponse({ error }) )
    }

    dispatch( StoreActions.getOurProductsResponse({ products: response.data }) )
  }
}

StoreActions.uploadProductPhoto = file => {
  return async dispatch => {
    try {
      dispatch( StoreActions.uploadProductPhotoRequest() )

      const url = await uploadFile(file)

      if (!url) {
        const error = { userMessage: '', debugMessage: 'url file not found in response' }
        return dispatch( StoreActions.uploadProductPhotoResponse({ error }) )
      }

      dispatch( StoreActions.uploadProductPhotoResponse({ photo: url }) )
    } catch (error) {
      return dispatch( StoreActions.uploadProductPhotoResponse({ error }) )
    }
  }
}

export default StoreActions
