import React from 'react'

import ColorManager from '../../../../Components/ColorManager'

const ColorStep = ({ colors = [] }) => {
  return (
    <ColorManager
      colors={colors}
      onNewColor={ color => colors.push(color) } />
  )
}

export default ColorStep
