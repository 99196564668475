import Immutable from 'seamless-immutable'

export default Immutable({

  createStoreLoading: false,
  createStoreError: null,
  createdStore: false,

  findStoreLoading: false,
  findStoreError: null,

  updateStoreLoading: false,
  updateStoreError: null,
  updatedStore: false,

  getBrandsLoading: false,
  getBrandsError: null,

  getCategoriesLoading: false,
  getCategoriesError: null,

  createBrandLoading: false,
  createBrandError: null,

  createProductLoading: false,
  createProductError: null,
  createdProduct: false,

  getOurProductsLoading: false,
  getOurProductsError: null,

  uploadingProductPhoto: null,
  uploadingProductError: null,
  uploadedProductPhoto: null,

  data: null,

  newProduct: {
    name: '',
    description: '',
    category: {},
    brand: {},
    photos: [],
    full_description: '',
    price: '',
    available_color: '',
  },

  brands: [],
  categories: [],
  products: [],

})
