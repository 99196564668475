import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import AccountActions from '../../Redux/Account/actions'
import { useDispatch, useSelector } from 'react-redux'

import styles from './personal-data-styles.module.sass'
import clsx from 'clsx'

const validationSchema = Yup.object({
  firstname: Yup.string()
    .required('Campo requerido *'),
  lastname: Yup.string()
    .required('Campo requerido *'),
  address: Yup.string()
    .required('Campo requerido *'),
  // dniType: Yup.string()
  //   .required('Campo requerido *'),
  dni_file: Yup.mixed().required('Campo requerido *'),
  dni: Yup.string()
    .required('Campo requerido *'),
  phone: Yup.string()
    .required('Campo requerido *'),
})

const PersonalData = ({ history }) => {

  const dispatch = useDispatch()

  const profile = useSelector( state => state.auth.user )

  const {
    updatingProfile,
    // updatingError, // TODO: SHOW ERROR
    updatedProfile
  } = useSelector( state => state.account )

  useEffect( () => {
    if (updatedProfile) {
      dispatch( AccountActions.resetUpdateProfile() )
      history.push('/user/store')
    }
  }, [ updatedProfile, history, dispatch ])

  return (
    <div>
      <NavigationTopBar />
      <section className="section">
        <div className="container">
          <h1 className="title">Datos personales</h1>
          <h2 className="subtitle">

          </h2>
          <div>
            <Formik
              initialValues={ {...profile, firstname: "", lastname: "", address: "", dni: "", idType: "", id: "", phone: "", dni_file: "" } }
              validationSchema={validationSchema}
              onSubmit={ values => dispatch( AccountActions.updateProfileBasics(values) ) } >

              { ({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Nombres</label>
                    <div className="control">
                      <Field className="input" type="text" name="firstname" />
                      <ErrorMessage name="firstname" component="span" className="has-text-danger" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Apellidos</label>
                    <div className="control">
                      <Field className="input" type="text" name="lastname" />
                      <ErrorMessage name="lastname" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Dirección</label>
                    <div className="control">
                      <Field className="input" type="text" name="address" />
                      <ErrorMessage name="address" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Tipo de identificación</label>
                    <div className="select">
                      <select>
                        <option> Cedula de ciudadania </option>
                        <option> Cedula de extranjeria </option>
                      </select>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Numero de identificación</label>
                    <div className="control">
                      <Field className="input" type="text" name="dni" />
                      <ErrorMessage name="dni" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Documento de identificación al 150%</label>
                    { values.dni_file instanceof File ? (
                      <div>
                        <img
                          className={styles.dni_file}
                          src={ URL.createObjectURL(values.dni_file)  }
                          alt="" />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={styles.dni_file}
                          src={ values.dni_file }
                          alt="" />
                      </div>
                    )}

                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          name="dni_file"
                          onChange={ event => setFieldValue('dni_file', event.currentTarget.files[0])} />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload"></i>
                          </span>
                          <span className="file-label">
                            Buscar archivo
                          </span>
                        </span>
                      </label>
                    </div>
                    <ErrorMessage name="dni_file" component="span" className="has-text-danger" />
                  </div>

                  <div className="field">
                    <label className="label">Teléfono</label>
                    <div className="control">
                      <Field className="input" type="text" name="phone" />
                      <ErrorMessage name="phone" component="span" className="has-text-danger" />
                    </div>
                  </div>

                  <div className="field is-grouped">
                    <div className="control">
                      <button type="submit" className={clsx("button","is-link", updatingProfile ? "is-loading" : null) }>Continuar</button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>

    </div>
  )
}

export default PersonalData
