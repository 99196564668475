import React, { useState, useEffect, Fragment } from 'react'

import Loader from '../../../../../Components/Loader'

import styles from './styles.module.sass'

const BrandStep = ({ values, value, onChange, onNew, onSelect, loading }) => {

  const [filteredValues, setFilteredValues] = useState([])
  const [currentInputValue, setCurrentInputValue] = useState(null)
  const [showPopup, setShowPopup] = useState(false)

  useEffect( () => {
    const filtered = values.filter( value => value.name.includes(currentInputValue) )
    setFilteredValues(filtered)
  }, [values, currentInputValue])


  useEffect( () => {
    setCurrentInputValue(value?.name || '' )
  }, [value])

  return (
    <div>
      <div className="dropdown is-active">
        <div className="dropdown-trigger">
          <div className={styles.field} aria-haspopup="true" aria-controls="dropdown-menu">
            <input
              className="input"
              type="text"
              value={currentInputValue || ""}
              onChange={ event => {
                setShowPopup(event.target.value !== "")
                setCurrentInputValue(event.target.value)
              }} />
          </div>
        </div>
        { showPopup && (
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">

              { filteredValues.map( (brand, index) => (
                <a
                  key={`brand-${index}`}
                  className="dropdown-item"
                  onClick={ event => {
                    event.preventDefault()
                    onSelect(brand)
                    setShowPopup(false)
                    setCurrentInputValue(brand.name)
                  }}>
                  { brand.name }
                </a>
              )) }

              { filteredValues.length === 0 && (
                <Fragment>
                  <hr className="dropdown-divider" />
                  <a href="#"
                    onClick={ event => {
                      event.preventDefault()
                      onNew(currentInputValue)
                    } }
                    className="dropdown-item">
                    Create: { currentInputValue }
                    { loading && (
                      <Loader />
                    ) }
                  </a>
                </Fragment>
              )}

            </div>
          </div>
        ) }
      </div>
    </div>
  )
}

export default BrandStep
