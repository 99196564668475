import React from 'react'
import { Link } from 'react-router-dom'
import i18n from 'i18next'

import AccountActions from '../../Redux/Account/actions'
import { useDispatch, useSelector } from 'react-redux'

const NavigationTopBar = () => {

  const dispatch = useDispatch()
  const profile = useSelector( state => state.auth.user )

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand is-spaced">
        <Link
          to="/home"
          className="navbar-item">
          <img src={require("../../Assets/img/logo-itto-blanco.png")} alt="itto" />
        </Link>
      </div>
      <div className="navbar-end">
        <div className="navbar-item has-dropdown is-hoverable">

          <Link
            to="/account"
            className="navbar-link">{ i18n.t("myAccount") }</Link>

          <div className="navbar-dropdown is-right">
            <div className="navbar-item">
              { profile && (
                profile.email
              )}
            </div>
            <div className="navbar-item is-pulled-right">
              <Link
                to="/account?edit"
                className="button is-link">{ i18n.t("editProfile") }</Link>
            </div>
            <div className="is-clearfix" />
            <hr className="navbar-divider"/>
            <div
              onClick={ () => dispatch( AccountActions.logOut() ) }
              className="navbar-item">
              { i18n.t("signOut") }
            </div>
          </div>
        </div>

      </div>
    </nav>
  )
}

export default NavigationTopBar
