import React, { Component } from 'react'

import './Loader.css'

export default class Loader extends Component {
  render() {
    return (
      <div className="LoaderContainer">
        <img src={ require('../../Assets/img/loader.gif') } alt="loader" />
      </div>
    )
  }
}
