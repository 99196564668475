import React, { useRef, useState } from 'react'

import styles from './styles.module.sass'
import clsx from 'clsx'

const Photo = ({ source, isMain }) => {
  return (
    <div
      className={styles.container_photo}>
      { source instanceof File ? (
        <img className={styles.photo} src={URL.createObjectURL(source)} alt="" />
      ) : (
        <img className={styles.photo} src={source} alt="" />
      )}
      { isMain && (
        <div className={styles.is_main_photo} />
      )}
    </div>
  )
}

const PhotoManager = ({ multiple = false, photos = [], onSelectFiles, onDelete, onMarkAsMain, isLoading }) => {

  const hiddenFileInput = useRef(null)
  const [activePopup, setActivePopup] = useState(-1)

  return (
    <div className={styles.container_photo_manager}>

      { photos.map( (photo, idx) => (
        <div key={`photo-${idx}`} className="dropdown is-active">

          <div className="dropdown-trigger">
            <div
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={ () => setActivePopup( activePopup === idx ? -1 : idx )}>
              <Photo
                isMain={photo.isMain}
                source={photo.source} />
            </div>

          </div>


          { activePopup === idx && (
            <div className="dropdown-menu" id="dropdown-menu">
              <div className="dropdown-content">
                <div
                  className="dropdown-item"
                  onClick={ () => {
                    onDelete(idx)
                    setActivePopup(-1)
                  }}>
                  Eliminar
                </div>
                <hr className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  onClick={ () => {
                    onMarkAsMain(idx)
                    setActivePopup(-1)
                  }}>
                  Marcar como principal
                </div>
              </div>
            </div>
          )}
        </div>
      ) )}



      { isLoading && (
        <div
          className={clsx(styles.container_photo, styles["is-loading"] )}>
        </div>
      ) }

      <div
        className={styles.container_photo_new}
        onClick={ () => {
          hiddenFileInput.current.click()
          setActivePopup(-1)
        }}>
        <input
          multiple={multiple}
          ref={hiddenFileInput}
          accept="image/*"
          id="photo-file"
          type="file"
          onChange={ event => {
            onSelectFiles(event.target.files)

          }}
          style={{ display: "none" }} />
        <i className="fa fa-plus-circle" aria-hidden="true"></i>
      </div>

    </div>
  )
}


export default PhotoManager
