import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './'
import { composeWithDevTools } from 'redux-devtools-extension'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  whitelist: ['auth', 'store'],
  blacklist: ['account'],
  transforms: [seamlessImmutableTransformCreator({})]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const useReduxDevTools = process.env.NODE_ENV === 'development'

const resetEnhancer = rootReducer => (state, action) => {
  if (action.type === 'RESET_STORE') {
    delete state.account
  }
  return rootReducer(state, action)
}

const middlewares = [
  thunk
]

const enhancers = []

enhancers.push( applyMiddleware(...middlewares) )

const composer = useReduxDevTools ? composeWithDevTools : compose

const store = createStore(resetEnhancer(persistedReducer), composer(...enhancers))

export const persistor = persistStore(store)

export default store
