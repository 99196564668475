import api from './'

export const createStore = store => {
  return api.post('stores', store)
}

export const findMyStore = () => {
  return api.get('stores/me')
}

export const updateStore = (id, params) => {
  return api.put(`stores/${id}`, params)
}

export const getCategories = () => {
  return api.get('categories?status=active')
}

export const getBrands = () => {
  return api.get('brands?status=active')
}

export const createBrand = name => {
  return api.post('brands', { name })
}

export const createProduct = params => {
  return api.post('products', params)
}

export const getOurProducts = () => {
  return api.get('products')
}
