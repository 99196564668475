import React from 'react'

import styles from './styles.module.sass'
import clsx from 'clsx'

const PreviewStep = ({ values }) => {

  const mainPhoto = values.photos.filter( photo => photo.isMain )?.[0] || values.photos[0]

  return (
    <div className="container p-5">

      <div className="columns">

        <div className="column is-one-third">

          <div className={styles.container_photos}>

            <div className={styles.main_photo}>
              { mainPhoto && (
                <img src={mainPhoto.source} alt="" />
              )}
            </div>

            <div className={styles.other_photos}>
              { values.photos.filter( photo => !photo.isMain ).map( (photo, idx) => (
                <div key={`photo-preview-${idx}`} className={styles.photo}>
                  <img src={photo.source} alt="" />
                </div>
              ) ) }
            </div>
          </div>

        </div>

        <div className="column has-text-left">
          <div className="is-size-4 has-text-weight-bold">
            { values.name }
          </div>

          <div>
            { values.brand.name }
          </div>

          <div>
            { values.description }
          </div>

          <div className={clsx("has-text-info", "is-size-3", "has-text-weight-bold", styles.price)}>
            { values.price }
          </div>

          <div>
            { values.available_color }
          </div>

          <div>
            { values.category.name }
          </div>
        </div>
      </div>
      <div>
        <div>
          { values.full_description }
        </div>
      </div>
    </div>
  )
}

export default PreviewStep
