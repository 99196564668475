import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'
import StoreManagerNavBar from '../../Components/StoreManager/NavBar'
import Product from '../../Components/Product'

import StoreActions from '../../Redux/Store/actions'

import { useSelector, useDispatch } from 'react-redux'

import styles from './styles.module.sass'

const HomeScreen = ({ history }) => {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const store = useSelector(state => state.store.data)
  const products = useSelector(state => state.store.products)

  useEffect( () => {
    dispatch( StoreActions.getOurProducts()  )
  }, [dispatch])


  return (
    <div>
      <NavigationTopBar />
      <div className="container">
        { user.status === "active" && store.status === "pending" ? (
          <div className={styles.store_pending_container}>
            <div>
              Tu tienda <b>{ store.name } </b> esta <span style={{ color: 'red' }}>pendiente</span> por aprobar
            </div>
            <div>
              Nuestro equipo está en estos momentos verificando la autenticidad
              de los documentos subidos al sistema, pronto estaremos en contacto
              para confirmar el estado de su solicitud.
            </div>
          </div>
        ): (
          <div>
            <StoreManagerNavBar
              history={history}
              storeName={store.name}
              storeLogo={store.logo} />
            <div>
              <div className="columns is-multiline">
                { products?.map( product => (
                  <div className="column is-quarter">
                    <Product value={product} />
                  </div>
                )) }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomeScreen
