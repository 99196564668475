import React from 'react'

const NameStep = ({value, onChange}) => {
  return (
    <div className="field">
      <p className="control">
        <input
          name="name"
          className="input"
          type="text"
          placeholder="Nombre"
          value={value}
          onChange={onChange} />
      </p>
    </div>
  )
}

export default NameStep
