import { create } from 'apisauce'

const api = create({
  // baseURL: 'https://felipecarreramo.usw1.kubesail.io',
  // baseURL: 'https://fullstaff.usw1.kubesail.io',
  baseURL: process.env.REACT_APP_API_BASE_URL,


})

export default api
