import React, { useState } from 'react'

import { SketchPicker } from 'react-color'

import styles from './styles.module.sass'
import clsx from 'clsx'

const Color = ({ value, children, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{ backgroundColor: value }}
      className={clsx(styles.container_color, className) }>
      { children }
    </div>
  )
}

const ColorManager = ({ colors = [], onNewColor }) => {

  const [showColorPicker, setShowColorPicker] = useState(false)
  const [currentColor, setCurrentColor] = useState('')

  const onChange = (color, event) => {
    setCurrentColor(color.hex)
  }

  const onSelect = () => {
    onNewColor(currentColor)
    setShowColorPicker(false)
  }

  return (
    <div className={styles.container_colors}>
      <Color
        onClick={ () => setShowColorPicker( !showColorPicker) }
        className={styles.container_color_new}
        key={`color-new`}
        value="#fff">
        <i className="fa fa-plus-circle" aria-hidden="true"></i>
      </Color>
      { colors.map( (color, idx) => (
        <Color
          key={`color-${idx}`}
          value={color} />
      ) )}
      { showColorPicker && (
        <div className={styles.container_picker}>
          <SketchPicker
            color={currentColor}
            onChange={onChange}
            />
          <div>
            <button
              className="button is-fullwidth"
              onClick={ () => onSelect() }>
              Aceptar
            </button>
          </div>
        </div>
      ) }
    </div>
  )
}

export default ColorManager
