import React from 'react'

const CategoryStep = ({values, value, onChange}) => {
  return (
    <div>
      <div className="select">
        <select
          name="category"
          value={value?.name || ""}
          onChange={ event => {
            const category = values.filter( value => value.name === event.target.value )?.[0]
            console.log(category)
            onChange(category)
          }}>
          { values.map( (category, index) => (
            <option key={`category-${index}`}>{ category.name }</option>
          )) }
        </select>
      </div>
    </div>
  )
}


export default CategoryStep
