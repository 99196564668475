import React, { useState } from 'react'

import AccountActions from '../../Redux/Account/actions'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '../../Components/Loader'

import './Styles/LoginScreenStyles.css'

import i18n from 'i18next'

const LoginScreen = ({ history }) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { loginLoading, loginError } = useSelector( state => state.account )
  const dispatch = useDispatch()

  function onSubmit() {
    dispatch( AccountActions.login(email, password) )
  }

  function showSignupScreen() {
    history.push('/register')
  }

  function showForgotPasswordScreen() {
    history.push('/forgot-password')
  }

  return (
    <div className="columns">
      <div className="column">
      { loginError && (
        <div className="columns">
          <div className="column is-4 is-offset-4">
            <div className="notification is-danger has-text-centered">
            { loginError.message }
            </div>
          </div>
        </div>
      )}

        <div className="columns">
          <div className="column is-4 is-offset-4" id="login-form">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{ i18n.t("email") }</label>
                  <div className="control">
                    <input
                      className="input"
                      value={email}
                      onChange={ event => setEmail(event.target.value) }
                      type="email"
                      placeholder={ i18n.t("email") } />
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{ i18n.t("password") }</label>
                  <div className="control">
                    <input className="input"
                      type="password"
                      value={password}
                      onChange={ event => setPassword(event.target.value) }
                      placeholder={ i18n.t("password") } />
                  </div>
                </div>
              </div>
            </div>

            <div className="columns is-vcentered">
              <div className="column is-4">
                <div className="field">
                  <div className="control">
                    <button
                      onClick={ () => onSubmit() }
                      className="button is-link">
                      { i18n.t("signIn") }
                      </button>
                  </div>
                </div>
              </div>

              { loginLoading && (
                <div className="column is-paddingless">
                  <Loader />
                </div>
              )}
            </div>

            <div className="columns">
              <div className="column">
                { i18n.t("signUpLabel") }?
                <div onClick={ () => showSignupScreen() }>
                   { " " + i18n.t("signUp") }
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div
                  onClick={ () => showForgotPasswordScreen() }>
                   { " " + i18n.t("forgotPassword") }
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default LoginScreen
