import { createActions } from 'redux-actions'

import {
  me,
  setAuthorizationToken,
  restoreAuthorizationToken,
  removeCurrentAuthorizationToken,
} from '../../Services/Account'

import StoreActions from '../Store/actions'

const AuthActions = createActions({
  SET_USER: user => ({ user }),
  VERIFY_AUTH: () => {},
  RESTORED_AUTH: () => {},
  NO_AUTH: () => {},
  ACCESS_TOKEN_SAVED: token => ({ token }),
})

AuthActions.restoreAuth = () => {
  return async dispatch => {
    try {
      dispatch( AuthActions.verifyAuth() )
      restoreAuthorizationToken()
      const meResponse = await me()
      console.log('meResponse', meResponse)
      dispatch(AuthActions.setUser( meResponse ))
      await dispatch(StoreActions.findMyStore())
      dispatch( AuthActions.restoredAuth() )
    } catch (err) {
      dispatch( AuthActions.noAuth() )
    }
  }
}

AuthActions.removeAuth = () => {
  return async dispatch => {
    setAuthorizationToken(null)
    removeCurrentAuthorizationToken()
    dispatch( AuthActions.noAuth() )
    dispatch( { type: 'RESET_STORE' } )
  }
}

AuthActions.setAccessToken = token => {
  return dispatch => {
    setAuthorizationToken(token)
    dispatch( AuthActions.accessTokenSaved(token) )
  }
}

export default AuthActions
