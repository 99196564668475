import React from 'react'

const StoreNavBar = ({ history, storeName, storeLogo }) => {
  return (
    <nav className="level my-6 px-4">
      <div className="level-left">

        <div className="card">
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img src={storeLogo} alt="" />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-4">{storeName}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="level-right">
        <div className="field has-addons">
          <p className="control ">
            <button
              className="button px-4"
              onClick={ () => history.push('/categories') }>
              <span>Ver Categorías</span>
            </button>
          </p>
          <p className="control">
            <button
              className="button px-4"
              onClick={ () => history.push('/product/new') }>
              <span>Crear Producto</span>
            </button>
          </p>
        </div>
      </div>
    </nav>
  )
}

export default StoreNavBar
