import React, { useEffect } from 'react'

import { routes } from './Navigation/router'

import AuthActions from './Redux/Auth/actions'

import { useDispatch, useSelector } from 'react-redux'

import './index.sass'
import './template.sass'

const App = () => {

  const dispatch = useDispatch()
  const authLoading = useSelector( state => state.auth.loading )
  const isAuthenticated = useSelector( state => state.auth.access_token !== null && state.auth.user !== null )
  const store = useSelector( state => state.store.data )

  useEffect( () => {
    dispatch( AuthActions.restoreAuth() )
  }, [dispatch])

  return (
    <main role="application">
      { routes(store, isAuthenticated, authLoading) }
    </main>
  )
}

export default App
