import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import HomeScreen from '../Containers/Home'
import LoginScreen from '../Containers/Account/LoginScreen'
import SignupScreen from '../Containers/Account/SignUpScreen'
import AccountScreen from '../Containers/Account/AccountScreen'
import ChangePasswordScreen from '../Containers/Account/ChangePasswordScreen'
import ForgotPasswordScreen from '../Containers/Account/ForgotPasswordScreen'
import LoadingScreen from '../Containers/LoadingScreen'

import PersonalData from '../Containers/User/PersonalDataScreen'
import Store from '../Containers/User/StoreScreen'
import LegalRepresentative from '../Containers/User/LegalRepresentativeScreen'
import UserConfirmation from '../Containers/User/ConfirmationScreen'

import NewProduct from '../Containers/Product/New'
import Categories from '../Containers/Categories'

export function routes(store, isAuthenticated, isLoading) {

  if (isLoading) {
    return (
        <Router>
          <Switch>
            <Route
              path="*"
              exact
              component={LoadingScreen} />
          </Switch>
        </Router>
      )
  }

  if (isAuthenticated) {

    let defaultRoute = 'home'

    if (store === undefined || store === null ) {
      defaultRoute = '/user/personal-data'
    }else if (store === undefined || store === null ) {
      defaultRoute = '/user/store'
    }else if (store?.legal === undefined || store?.legal === null  ) {
      defaultRoute = '/user/legal-representative'
    }

    // TODO: render just the signup step if user is not completed

    return (
        <Router>
          <Switch>
            <Route
              path="/user/personal-data"
              exact
              component={PersonalData} />

            <Route
              path="/user/store"
              exact
              component={Store} />

            <Route
              path="/user/legal-representative"
              exact
              component={LegalRepresentative} />

            <Route
              path="/user/confirmation"
              exact
              component={UserConfirmation} />

            <Route
              path="/home"
              exact
              component={HomeScreen} />

            <Route
              path="/product/new"
              exact
              component={NewProduct} />

            <Route
              path="/categories"
              exact
              component={Categories} />

            <Route
              path="/account"
              exact
              component={AccountScreen} />

            <Route
              path="/change-password"
              exact
              component={ChangePasswordScreen} />

            <Route render={ () => <Redirect to={defaultRoute} /> } />

          </Switch>
        </Router>
      )
  }
  return (
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            component={LoginScreen} />

          <Route
            path="/register"
            exact
            component={SignupScreen} />

          <Route
            path="/forgot-password"
            exact
            component={ForgotPasswordScreen} />

          <Route render={ () => <Redirect to="/" /> } />

        </Switch>
      </Router>
    )
}
