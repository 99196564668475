import React from 'react'

const DescriptionStep = ({value, onChange}) => {
  return (
    <textarea
      name="description"
      className="textarea"
      placeholder="Descripción"
      value={value}
      onChange={onChange}>
    </textarea>
  )
}

export default DescriptionStep
